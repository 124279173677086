.map {
  width: 100%;
  height: 1024px;
  position: relative;
  display: flex;

  &-block {
    width: 100%;
    @media (min-width: 1400px) {
      width: calc(100% - 750px);
    }
  }
}

.request-wrap{
  width: calc(100% - 10px);
  position: absolute;
  top: 55px;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-direction: column;
  border-radius: 11px 0px 0px 11px;
  background: transparent;

  @media (min-width: 576px) {
    width: calc(100% - 100px);
  }

  @media (min-width: 1400px) {
    max-width: 750px;
    bottom: 0;
    top: 0;
    right: 0;
    transform: none;
    background-color: var(--white);
    justify-content: space-between;
    padding: 145px 15px 45px 15px;

    overflow: hidden;
  }

  .inputs-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (min-width: 1400px) {
      padding: 0;
    }
    h3 {
      display: none;
      color: #000;
      text-align: right;
      font-family: var(--font-accent);
      font-size: 80px;
      font-weight: 400;
      line-height: 75.469px;
      margin-bottom: 53px;

      @media (min-width: 1400px) {
        display: block;
      }

      span {
        color: #001E43;
        font-weight: 700;
        line-height: 94.336%;
      }
    }

    &-block {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px 15px;
      border: 0.5px solid black;
      border-radius: 20px;
      margin-bottom: 15px;
      background: var(--white);

      @media (min-width: 1400px) {
        padding: 15px 35px;
      }
    }

  }
}

.action-wrap {
  display: flex;
  justify-content: center;
  position: relative;

  @media (min-width: 1400px) {
    padding: 0;
    margin-top: 25%;
  }

  &-btn {
    width: 100%;
    position: absolute;
    border-radius: 16px;
    background: var(--btn-bg);
    padding: 15px 20px;
    color: var(--white);
    font-family: var(--font-main);
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    cursor: pointer;
    z-index: 2;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;


    @media (min-width: 576px) {
      padding: 15px 115px;
    }

    @media (min-width: 1400px) {
      width: auto;
      min-width: 395px;
      bottom: 45px;
      line-height: 42px;
      font-size: 36px;
      height: 73px;

    }

    .button-content {
      position: relative;
      z-index: 1;
    }
  }
}

.markers-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  padding: 20px 0;

  &-top, &-bottom {
    width: 20px;
    height: 31px;
    img {
      width: auto;
    }
  }
  &-top {
    margin-bottom: auto;
  }
  &-bottom {
    margin-top: auto;
  }

  &-divider {
    width: 0.5px;
    height: 100%;
    background: #405B7C;
  }
}
.inputs-block {
  width: 100%;
  margin: 0 25px;
  position: relative;

  input {
    border: none;
    width: 100%;
    padding: 20px 10px;
    color: var(--btn-bg);
    font-family:  var(--font-main);
    font-size: 18px;
    font-weight: 400;
    line-height: 17px;

    &::placeholder {
      color: rgba(151, 151, 151, 0.59);
      font-family: var(--font-main);
      font-size: 24px;
      font-weight: 400;
      line-height: 2.641px;
    }
  }

  .input-wrap {
    position: relative;
    width: 100%;

    .input-from-response-data, .input-to-response-data {
      display: none;
      position: absolute;
      width: 100%;
      top: 50px;
      background: var(--white);
      border-radius: 0 0 16px 16px;
      border: 0.5px solid #000;
      z-index: 3;
      overflow-y: scroll;
      height: auto;
      max-height: 350px;

      .input-data-options {
        padding: 15px;
        cursor: pointer;

        &:hover {
          background: rgba(0, 35, 80, 0.10);
        }
      }
    }
  }



  &-divider {
    width: 100%;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 0.5px;
      background-color: #002450;
    }

    &--stop {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 26px;
      height: 26px;
      border-radius: 50%;
      border: 1px solid #002450;
      background: var(--white);
      z-index: 2;
      color: #002450;
      font-family: var(--font-main);
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      cursor: pointer;
    }

    &--stop::after {
      content: 's';
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }
}

.icons-wrap {
  display: flex;
  flex-direction: column;
  margin-left: auto;

  &-icon {
    margin-bottom: 25px;
    cursor: pointer;

    .reserved-active {
      display: none;
    }
    img {
      width: 31px;
      height: 31px;
    }
  }
}

.error-msg {
  display: none;
  color: var(--error);
  font-size: 16px;
  font-family: var(--font-main);
  padding: 12px 5px;
}

.reserved-time-view {
  display: none;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  align-items: center;

  @media (min-width: 576px) {
    margin-top: 30px;
  }

  .time-view-description, .time-view-date {
    font-size: 12px;
    margin-right: 10px;
    font-weight: 700;
    color: var(--text-accent);
  }

  @media (min-width: 576px) {
    .time-view-description, .time-view-date {
      font-size: 20px;
    }
  }
}

.reservation-wrap {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 0.5px solid #000;
  border-radius: 20px;
  background: var(--white);
  position: relative;
  padding: 60px 15px 15px 15px;
  margin-bottom: 10px;

  @media (min-width: 576px) {
    padding: 15px;
  }


  .date-picker-block {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;


    @media (min-width: 1400px) {
      padding: 37px 63px 0px 63px;
    }

    .date-error {
      display: none;
      font-size: 12px;
      color: var(--error);
    }
  }

  .description-block {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;


    @media (min-width: 576px) {
      padding: 0 40px;
      margin-bottom: 25px;
    }


    &-item {
      margin-bottom: 15px;
      h2 {
        font-size: 20px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 5px;
        text-align: left;
      }
      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 15px;
        margin: 0;
        padding: 0;
        text-align: left;
      }
    }
  }

  .reservation-set-btn {
    width: 300px;
    height: 55px;
    flex-shrink: 0;
    border-radius: 23px;
    background: #F00;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;

    @media (min-width: 576px) {
      width: 331px;
    }
  }

  h2 {
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 12px;

    @media (min-width: 576px) {
      font-size: 30px;
    }
  }

  p {
    margin: 0 0 25px 0;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;

    @media (min-width: 576px) {
      font-size: 20px;
    }
  }

  &-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    @media (min-width: 576px) {
      top: 22px;
      right: 27px;
    }
  }

  .select-date-wrap {
    position: relative;
    height: 100%;
    text-align: center;
    overflow: hidden;
    font-size: 20px;
    color: var(--white);
    &:before, &:after {
      position: absolute;
      z-index: 1;
      display: block;
      content: '';
      width: 100%;
      height: 50%;
    }
    &:before {
      top: -20px;
      background-image: linear-gradient(to bottom, #fffffff5, #ffffff2e);
    }
    &:after {
      bottom: -20px;
      background-image: linear-gradient(to top, #fffffff5, #ffffff2e);
    }

    .select-options {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 0;
      transform-style: preserve-3d;
      margin: 0 auto;
      display: block;
      transform: translateZ(-150px) rotateX(0deg);
      -webkit-font-smoothing: subpixel-antialiased;
      color: #666;
      .select-option {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;

        -webkit-font-smoothing: subpixel-antialiased;
        @for $i from 1 through 100 {
          &:nth-child(#{$i}) {
            transform: rotateX(-18deg * ($i - 1)) translateZ(150px);
          }
        }
      }
    }
  }


  .highlight {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    overflow: hidden;
    color: #000;
    ul {
      padding-left: 0;
    }
  }

  .highlight-list {
    position: absolute;
    width: 100%;
  }

  /* date */
  .date-selector {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    height: 200px;
    position: relative;
    padding: 0 10px;

    @media (min-width: 576px) {
      padding: 0 50px;
    }


    > div {
      flex: 1;
    }

    .day-wrap {
      flex: 3;
    }

    .mid-block {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: rgba(217, 217, 217, 0.40);
      height: 45px;
      width: 100%;
      border-radius: 13px;
    }

  }
}

.chose-car-wrap {
  display: none;
  width: 100%;
  border: 0.5px solid #000000;
  border-radius: 20px;
  padding-top: 50px;
  flex-direction: column;
  z-index: 2;
  background: var(--white);
  padding-bottom: 120px;

  @media (min-width: 1400px) {
    height: 100%;
    padding-bottom: 0;
  }


  h4 {
    color: #979797;
    font-family: var(--font-main);
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 30px;
    padding-left: 35px;
    @media (min-width: 1400px) {
      margin-bottom: 50px;
    }
  }

  .car-item {
    width: 100%;
    display: flex;
    padding: 15px 10px;
    cursor: pointer;

    @media (min-width: 576px) {
      padding: 15px 60px;
    }

    @media (min-width: 1400px) {
      padding: 35px 22px;
    }

    &:hover {
      background: rgba(0, 35, 80, 0.10);
    }

    &:first-of-type {
      margin-top: 0;
    }

    &-img {
      margin-right: auto;
    }

    &-description {
      width: 100%;
      margin: 0 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: var(--font-main);

      &--top {
        color: #002757;
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;

        #arrival-time-sedan, #arrival-time-minivan, #arrival-time-suv {
          margin-left: 10px;
        }

        @media (min-width: 1400px) {
          font-size: 28px;
          line-height: 33px;
        }


      }
      &--bottom {
        font-size: 16px;
        font-weight: 300;
        line-height: 19px;
      }
    }

    &-price {
      margin-left: auto;
      font-family: var(--font-main);
      font-size: 28px;
      font-weight: 500;
      line-height: 33px;
    }
  }

  .car-item.disabled-item {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .car-selects {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0 10px;
    flex-direction: column;

    & > div {
      margin-bottom: 20px;
    }

    @media (min-width: 576px) {
      padding: 0 10px;
      flex-direction: row;
      justify-content: space-between;

      & > div {
        margin-bottom: 0;
      }
    }

    @media (min-width: 768px) {
      padding: 0 80px;
    }


    @media (min-width: 1400px) {
      padding: 0 22px;
      margin-top: 20px;
    }


    .pass-title, .pet-title, .childseats-title, .meet-greet-title {
      color: #979797;
      font-family: var(--font-main);
      font-size: 16px;
      font-weight: 400;
      line-height: 18.867px;
      display: none;
    }

    .pass-title {
      display: flex;
    }

    .select-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 11px;
      border-radius: 5px;
      border: 0.5px solid black;
      border-bottom: 2px solid black;
      cursor: pointer;
      position: relative;


      &-options {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        top: calc(100% + 5px);
        right: 0;
        left: 0;
        border-radius: 15px;
        border: 0.5px solid black;
        z-index: 20;
        background: var(--white);
        max-height: 190px;
        overflow: scroll;

        &--option {
          display: flex;
          justify-content: center;
          padding: 10px 0;
          width: 100%;

          &:hover {
            font-weight: 500;
          }
        }

        .active {
          font-weight: 700;
        }
      }

      #pet-options {
        @media (min-width: 576px) {
          width: 300px;
          left: 50%;
          transform: translate(-50%);
        }

      }

      #childseats-options {
        @media (min-width: 576px) {
          width: 300px;
          left: 50%;
          transform: translate(-50%);
        }
      }
      #meet-greet-options {
        @media (min-width: 576px) {
          width: 225px;
          left: 50%;
          transform: translate(-50%);
        }
      }



      #pass, #pet, #childseats, #meet-greet {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      picture {
        display: flex;
      }

      img {
        width: 19px;
        height: 19px;
        margin-left: 10px;
      }
    }

    .pass-wrap {
      width: 100%;
      @media (min-width: 576px) {
        width: 98px;
      }
    }

    .pet-wrap {
      width: 100%;
      @media (min-width: 576px) {
        width: 125px;
      }
    }

    .childseats-wrap {
      width: 100%;
      @media (min-width: 576px) {
        width: 120px;
      }
    }

    .meet-greet-wrap {
      width: 100%;
      @media (min-width: 576px) {
        width: 190px;
      }
    }
  }

  .btn-mobile-wrap {
    width: 100%;
    padding: 0 40px;
  }
}

.payment-details {
  display: none;
  width: 100%;
  height: 100%;
  border: 0.5px solid #000000;
  border-radius: 20px;
  padding: 50px 30px 0 30px;
  flex-direction: column;
  z-index: 2;

  .stripe-logo-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .stripe-logo {
      width: 100px;
    }

  }



  &-description {
    color: #7E7E7E;
    font-family: var(--font-main);
    font-size: 20px;
    font-weight: 300;
    line-height: 19px;
    margin-top: 9px;
  }

  h4 {
    color: #979797;
    font-family: var(--font-main);
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 50px;
  }
  .card-wrap {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    .prefix {
      position: absolute;
      display: flex;
      top: 15px;
      left: 15px;
      width: 25px;
      height: 20px;
      align-items: center;
      justify-content: center;
    }

    .card-input {
      padding-left: 50px;
    }
  }

  .name-inputs-wrap {
    width: 100%;
    margin-bottom: 40px;


    .phone-wrap {
      position: relative;
      width: 100%;
      margin-left: 10px;
      .prefix {
        position: absolute;
        display: flex;
        width: 51px;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: #E6E9EE;
        border: 1px solid #000;
        border-radius: 5px 0 0 5px;
      }
      .phone-input {
        padding-left: 65px;
      }
    }
  }

  .input-block {
    width: 100%;
    border-radius: 5px;
    background: #FFF;
    padding: 16px 20px;
    border-top: 0.4px solid #002350;
    border-right: 0.4px solid #002350;
    border-bottom: 1px solid #002350;
    border-left: 0.4px solid #002350;

    &::placeholder {
      color: rgba(151, 151, 151, 0.59);
      font-family: var(--font-main);
      font-size: 20px;
      font-weight: 400;
      line-height: 2.641px;
    }
  }
  .input-block.error {
    color: var(--error);
  }

  .exp-date-wrap {
    display: flex;
    .card-expiry-wrap,
    .card-cvc-wrap {
      max-width: 110px;
      margin-right: 15px;
    }

  }

  .payment-error-message {
    color: var(--error);
    font-size: 12px;
  }
}

.selected-car-wrap {
  display: none;
  width: 100%;
  border-radius: 20px;
  border: 0.5px solid #000000;
  padding: 16px 0;
  margin-bottom: 15px;

  .car-item {
    width: 100%;
    display: flex;
    padding: 35px 10px;
    cursor: pointer;

    @media (min-width: 576px) {
      padding: 35px 22px;
    }


    &-img {
      margin-right: auto;
    }

    &-description {
      width: 100%;
      margin: 0 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: var(--font-main);

      &--top {
        color: #002757;
        font-size: 28px;
        font-weight: 400;
        line-height: 33px;

        #arrival-time-sedan, #arrival-time-minivan, #arrival-time-suv {
          margin-left: 10px;
        }
      }
      &--bottom {
        font-size: 16px;
        font-weight: 300;
        line-height: 19px;
      }
    }

    &-price {
      margin-left: auto;
      font-family: var(--font-main);
      font-size: 28px;
      font-weight: 500;
      line-height: 33px;
    }
  }
}

.move-up {
  animation: moveUpAnimation 1s ease-in-out forwards;
}

.show-on {
  display: flex;
  animation: showOnAnimation 2s ease forwards;
}


.mobile-form-steps {
  display: none;
  width: 100%;
  box-shadow: 0px -6px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: -15px;
  border-radius: 32px 32px 0px 0px;
  position: relative;
  padding-top: 40px;
  padding-bottom: 110px;
  background: var(--white);
  //overflow: hidden;
  .chose-car-wrap {
    display: block;
    border-radius: 20px 20px 0 0;
    border: none;
    padding: 0;

    .car-item {
      &-img {
        img {
          width: 80px;

          @media (min-width: 576px) {
            width: auto;
          }
        }
      }
      &-description {
        &--top {
          font-size: 18px;
          @media (min-width: 576px) {
            font-size: 24px;
          }

        }
        &--bottom {
          font-size: 12px;
          @media (min-width: 576px) {
            font-size: 16px;
          }
        }
      }
      &-price {
        font-size: 24px;
        @media (min-width: 576px) {
          font-size: 28px;
        }
      }

    }


  }

  .btn-mobile-wrap {
    padding: 0 80px;
    margin-top: 35px;
  }

  .selected-car-wrap {
    border: none;
    padding: 0;
    margin-bottom: 30px;

    .car-item {
      padding: 22px 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .car-item-img, .car-item-price {
        margin: 10px auto;
      }

      .car-item-description {
        align-items: center;
      }

      @media (min-width: 576px) {
        padding: 22px 45px;
        flex-direction: row;
      }
    }

  }

  .payment-details {
    border: none;
    padding: 0 30px;
    position: relative;

    .stripe-logo-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .stripe-logo {
        width: 100px;
      }

    }



    @media (min-width: 576px) {
      padding: 0 70px;
    }


    h4 {
      margin-bottom: 20px;
      margin-left: -20px;
    }

    .name-inputs-wrap, .card-wrap {
      margin-bottom: 20px;


    }

    .name-inputs-wrap {
      display: flex;
      flex-direction: column;
      .phone-wrap {
        margin-top: 20px;
        margin-left: 0;
      }

      @media (min-width: 576px) {
        flex-direction: row;

        .phone-wrap {
          margin-top: 0;
          margin-left: 10px;
        }
      }

    }

    .payment-details-description {
      margin-bottom: 30px;
    }

  }


}

.loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal {
  width: 250px;
  height: 130px;
  position: absolute;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    &:hover {
      color: var(--btn-bg);
    }
  }
}

.modal-message {
  font-size: 20px;
  margin-bottom: 10px;
}

.modal-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes moveUpAnimation {
  from {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes showOnAnimation {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}