/* Fonts */
@font-face {
  font-family: 'SF UI Text';
  src: url("../fonts/sf-ui-text-light-webfont.woff2"),
  url("../fonts/sf-ui-text-light-webfont.woff");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'SF UI Text';
  src: url("../fonts/sf-ui-text-regular-webfont.woff2"),
  url("../fonts/sf-ui-text-regular-webfont.woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'SF UI Text';
  src: url("../fonts/sf-ui-text-medium-webfont.woff2"),
  url("../fonts/sf-ui-text-medium-webfont.woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Text';
  src: url("../fonts/sf-ui-text-bold-webfont.woff2"),
  url("../fonts/sf-ui-text-bold-webfont.woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-light-webfont.woff2"),
  url("../fonts/montserrat-light-webfont.woff");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-regular-webfont.woff2"),
  url("../fonts/montserrat-regular-webfont.woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-medium-webfont.woff2"),
  url("../fonts/montserrat-medium-webfont.woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-semibold-webfont.woff2"),
  url("../fonts/montserrat-semibold-webfont.woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-bold-webfont.woff2"),
  url("../fonts/montserrat-bold-webfont.woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

/* Base */
@import "./base/rest";
@import "./base/vars";
@import "./base/sticky-footer";

/* Blocks */
@import "./blocks/*.scss";