/* Nav Icon */
.mobile-nav-btn {
  position: absolute;
  right: 40px;
  top: 30px;
  --time: 0.1s;
  --width: 40px;
  --height: 30px;
  --line-height: 2px;
  --spacing: 6px;
  --radius: 4px;

  /* Dynamic height and width */
  height: calc(var(--line-height) * 3 + var(--spacing) * 2);
  width: var(--width);

  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-icon {
  position: relative;
  width: var(--width);
  height: var(--line-height);
  background-color: var(--white);
  border-radius: var(--radius);
}

.nav-icon::before,
.nav-icon::after {
  content: '';
  display: block;

  position: absolute;
  left: 0;

  width: var(--width);
  height: var(--line-height);

  border-radius: var(--radius);
  background-color: var(--white);
  transition: transform var(--time) ease-in,
  top var(--time) linear var(--time);
}

.nav-icon::before {
  top: calc(-1 * (var(--line-height) + var(--spacing)));
}

.nav-icon::after {
  top: calc(var(--line-height) + var(--spacing));
}

.nav-icon.nav-icon--active {
  background-color: transparent;
}

.nav-icon.nav-icon--active::before,
.nav-icon.nav-icon--active::after {
  top: 0;
  transition: top var(--time) linear,
  transform var(--time) ease-in var(--time);
}

.nav-icon.nav-icon--active::before {
  transform: rotate(45deg);
}

.nav-icon.nav-icon--active::after {
  transform: rotate(-45deg);
}

/* Layout */

.mobile-nav-btn {
  z-index: 999;
}

 @media (max-width: 1400px) {
 	.mobile-nav-btn {
 		display: block;
 	}
 }