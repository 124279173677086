.map-wrap {
  padding: 120px 0;
}

.description-wrap {
  background: linear-gradient(180deg, #E8EFF5 0%, #E8EFF5 53.65%, rgba(232, 239, 245, 0.00) 100%);
  padding-top: 73px;
  padding-bottom: 66px;

  &-choose {
    margin-bottom: 120px;
    h1 {
      font-family: var(--font-accent);
      color: var(--text-accent);
      font-size: 52px;
      font-weight: 500;
      line-height: 112%;
      padding-left: 14px;
      @media (min-width: 1400px) {
        font-size: 64px;
        line-height: 112.836%;
        margin-bottom: 0;
      }

      span {
        color: #000;
        font-size: 28px;
        font-style: normal;
        font-family: var(--font-main);
        font-weight: 300;
        line-height: 31.594px;
        letter-spacing: 4.34px;
        @media (min-width: 1400px) {
          font-size: 36px;
          line-height: 40px;
          letter-spacing: 5.58px;
        }
      }
    }
  }

  .choose-list {
    margin-top: 35px;
    margin-bottom: 65px;

    @media (min-width: 1400px) {
      margin-top: 55px;
    }

    &-item:not(:last-child) {
      margin-bottom: 19px;

      @media (min-width: 1400px) {
        margin-bottom: 48px;
      }
    }

    .item-icon {
      img {
        width: 63px;
        height: 63px;
        @media (min-width: 1400px) {
          width: 84px;
          height: 84px;
        }
      }
    }

    .item-text {
      margin-left: 40px;
      @media (min-width: 1400px) {
        margin-left: 32px;
      }

      h4 {
        font-family: var(--font-main);
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 11px;
      }

      p {
        font-family: var(--font-main);
        font-size: 20px;
        font-weight: 300;
        line-height: 23px;
        margin: 0;
      }
    }
  }
}

.download-app {
  border-radius: 24px;
  background: var(--white);
  box-shadow: 6px 4px 13px 0px rgba(0, 0, 0, 0.25);
  padding: 34px 43px;

  @media (min-width: 1400px) {
    padding: 70px 74px;
  }

  &-text {
    margin-bottom: 20px;
    font-family: var(--font-accent);

    @media (min-width: 1400px) {
      margin-bottom: 0;
    }
    h2 {
      color: var(--text-accent);
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      margin: 0;
      @media (min-width: 1400px) {
        font-size: 43px;
      }

    }
    p {
      font-size: 28px;
      font-weight: 300;
      line-height: 121%;
      margin: 0;
      @media (min-width: 1400px) {
        font-size: 36px;
      }
    }
  }

  &-images {
    a:first-child {
      margin-right: 12px;
    }
    img {
      width: 100%;

      @media (min-width: 1400px) {
        width: 300px;
      }
    }
  }
}
.hand-icon {
  position: relative;
  img {
    width: 375px;
    position: absolute;
    top: -72px;
    right: 0px;

    @media (min-width: 1400px) {
      width: 100%;
      position: absolute;
      top: -40px;
      right: 0px;
    }
  }
}

.service-wrap {

  &-downtown {
    margin-bottom: 66px;
    img {
      width: 320px;
      height: 289px;
      border-radius: 39px;
      @media (min-width: 576px) {
        width: 385px;
      }
      @media (min-width: 1400px) {
        width: 475px;
      }
    }
  }

  &-text {

    h4 {
      font-family: var(--font-main);
      font-size: 28px;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 4.34px;
      margin-bottom: 27px;
      @media (min-width: 1400px) {
        font-size: 36px;
        line-height: 40px;
        letter-spacing: 5.58px;
        margin-bottom: 18px;
      }
    }

    h2 {
      color: var(--text-accent);
      font-family: var(--font-accent);
      font-size: 52px;
      font-weight: 500;
      line-height: 59px;
      margin-bottom: 17px;

      @media (min-width: 1400px) {
        font-size: 60px;
        line-height: 68px;
        margin-bottom: 40px;
      }
    }

    p {
      font-family: var(--font-main);
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 0;

      @media (min-width: 1400px) {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}