:root {
  /* Fonts */
  --font-main: 'SF UI Text', sans-serif;
  --font-accent: 'Montserrat', serif;

  /* Colors */
  --white: #ffffff;
  --error: #ff3030;
  --accent: #FAD343;
  --text-accent: #003572;
  --btn-bg: #002350;
}

.gmnoprint {
  z-index: 100;
}