.footer {
  padding: 35px;
  font-size: 32px;
  color: var(--white);
  background: linear-gradient(97deg, #001E46 2.68%, #00336F 98.08%);

  @media (min-width: 576px) {
    padding: 75px 45px 35px 80px;
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    @media (min-width: 576px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-left-block {
      margin-bottom: 40px;

      @media (min-width: 576px) {
        margin-bottom: 0;
      }

      h4 {
        font-family: var(--font-main);
        font-size: 20px;
        font-weight: 300;
        line-height: 23px;
        text-align: center;

        @media (min-width: 576px) {
          text-align: left;
        }
      }
    }

    &-right-block {
      display: flex;
      flex-direction: column;
      h2 {
        font-family: var(--font-main);
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 23px;
        margin-bottom: 15px;
      }
    }
  }

  &-description {
    color: var(--accent);
    text-align: center;
    font-size: 14px;
    font-family: var(--font-main);
    font-weight: 300;
    line-height: 16.497px;
  }
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: center;

  .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    picture {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .social-link:not(:last-child) {
    margin-right: 15px;
  }
}