.header {
  position: relative;
  background-image: url("../img/headerImg.png");
  background-size: cover;
  background-position: center center;
  background-color: #021326;

  &-nav {
    padding: 15px 0;
    background: linear-gradient(180deg, rgba(255, 253, 253, 0.4), rgba(252, 250, 250, 0.17), rgba(217, 217, 217, 0.00) 100%);

    @media (min-width: 1400px) {
     padding: 40px 0 34px 0;
    }

    img {
      width: 105px;
      height: 58px;
      flex-shrink: 0;

      @media (min-width: 1400px) {
        width: 173px;
        height: 96px;
        margin-left: 53px;
      }
    }
    &-menu {
      a {
        font-family: var(--font-main);
        font-weight: 400;
        color: var(--white);
        font-size: 28px;
      }
    }

    &-auth-wrap {
    }
  }

  &-img-block {
    padding-top: 100px;
    padding-bottom: 88px;
    h1 {
      font-family: var(--font-accent);
      padding: 0;
      color: var(--white);
      font-size: 58px;
      font-weight: 400;
      line-height: 55px;

      @media (min-width: 1400px) {
        font-size: 88px;
        line-height: 83px;
      }

      .accent-color {
        color: var(--accent)
      }
    }

  }

}

.login-action-btn, .sign-up-action-btn {
  min-width: 144px;
  padding: 6px 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--white);
  border-radius: 10px;
  font-family: var(--font-main);
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
}

.login-action-btn {
  color: var(--white);
}
.sign-up-action-btn {
  margin-left: 16px;
  color: #002754;
  background: var(--white);
  margin-right: 53px;

}
