.mobile-nav {
  position: fixed;
  top: 0;
  width: 100%;
  left: -200%;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background: rgba(0, 0, 0, 0.60);
  transition: all 0.2s ease-in;

  .mobile-menu-wrap {
    width: calc(100% - 90px);
    background: white;
    position: absolute;
    overflow: auto;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 33, 99, 0.90);
    padding: 80px 0 0 10px;

    @media (min-width: 576px) {
      padding: 118px 0 0 111px;
    }

    .close-wrap {
      position: absolute;
      right: 30px;
      top: 36px;

    }
  }
}

.mobile-nav--open {
  left: 0;
}

.mobile-nav a {
  color: var(--white);
}

.mobile-nav__list {
  display: flex;
  flex-direction: column;

  a {
    color: var(--white);
    font-size: 35px;
    font-weight: 400;
    margin-bottom: 28px;
  }

  & .active {
    font-weight: bold;
  }

  .menu-btn-wrap {
    width: 213px;
    .sign-up-action-btn {
      width: 100%;
      margin: 12px 0;
    }
  }

  .telephone-number {
    color: var(--white);
    font-size: 24px;
    font-weight: 400;
    line-height: 37.707px;
    margin-top: 40px;

    @media (min-width: 576px) {
      font-size: 32px;
    }
  }

  .social-links {
    margin: 36px 0;
    justify-content: start;
  }
}